import React from "react"
import { Link } from "gatsby"

const baseButtonStyle =
  "inline-grid items-center  px-3 pt-1 bg-white hover:bg-black  hover:text-white text-base uppercase tracking-wide font-bold text-black no-underline "

export const ButtonLinkInt = ({
  to = "#",
  className = "",
  text = "Button",
  color = "blue",
}) => {
  return (
    <Link to={to} className={` ${baseButtonStyle} ${className} `}>
      {text}
    </Link>
  )
}

export const ButtonLinkExt = ({
  href = "#",
  className = "",
  text = "Button",
  blank = false,
}) => {
  return (
    <a
      href={href}
      className={`no-underline bg-red-600 px-5 hover:bg-red-500 font-bold md:text-2xl text-xl pt-3 pb-2 tracking-wide cursor-pointer ${className}`}
      target={blank ? "_blank" : null}
      rel="noopener noreferrer"
    >
      {text}
    </a>
  )
}
