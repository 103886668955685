import React from "react"
import PortableText from "@sanity/block-content-to-react"
import { Link } from "gatsby"
import urlBuilder from "@sanity/image-url"
import { sanityConfig } from "../sanityConfig"
import { ButtonLinkExt } from "./Button"

const BlockContentPrimary = ({ blockData }) => {
  const urlFor = source => urlBuilder(sanityConfig).image(source)

  const serializer = {
    types: {
      galleryImage: props => {
        return (
          <figure className="flex justify-center">
            <div>
              <img
                src={urlFor(props.node.myImage).url()}
                alt={props.node.imageTitle}
              />
              <figcaption className="font-bold text-2xl text-center mt-3">
                {props.node.imageTitle && props.node.imageTitle}
              </figcaption>
            </div>
          </figure>
        )
      },
      buttonLink: props => {
        const alignment = props.node.alignment || "left"
        if (alignment === "center") {
          return (
            <div className="flex my-5 justify-center">
              <ButtonLinkExt
                href={props.node.href}
                target="_blank"
                rel="noopener noreferrer"
                blank={props?.node?.blank || false}
                text={props.node.buttonText}
              />
            </div>
          )
        } else if (alignment === "right") {
          return (
            <div className="flex my-5 justify-end">
              <ButtonLinkExt
                href={props.node.href}
                target="_blank"
                rel="noopener noreferrer"
                blank={props?.node?.blank || false}
                text={props.node.buttonText}
              />
            </div>
          )
        } else {
          return (
            <div className="flex my-5 justify-start">
              <ButtonLinkExt
                href={props.node.href}
                target="_blank"
                rel="noopener noreferrer"
                blank={props?.node?.blank || false}
              >
                {props.children}
              </ButtonLinkExt>
            </div>
          )
        }
      },
    },
    marks: {
      center: props => {
        // console.log("CenterProps", props)

        return <span className="text-center block">{props.children}</span>
      },
      justify: ({ children }) => (
        <span className="text-justify block">{children}</span>
      ),
      left: ({ children }) => (
        <span className="text-left block">{children}</span>
      ),
      right: ({ children }) => (
        <span className="text-right block">{children}</span>
      ),
      link: props => {
        if (props.mark.blank) {
          return (
            <a
              href={props.mark.href}
              target="_blank"
              rel="noopener noreferrer"
              className="underline text-nofnecGreen hover:text-nofnecRed"
            >
              {props.children}
            </a>
          )
        }
        return (
          <a
            href={props.mark.href}
            className="underline text-nofnecGreen hover:text-nofnecRed"
          >
            {props.children}
          </a>
        )
      },
      internalPostLink: props => {
        return (
          <span>
            <Link
              className="underline text-nofnecGreen hover:text-nofnecRed"
              to={`/news/${props.mark.item.slug.current}`}
            >
              {props.children}
            </Link>
          </span>
        )
      },
    },
    list: props =>
      console.log("list", props) ||
      (props.type === "bullet" ? (
        <ul className="list-disc pl-8 mb-5">{props.children}</ul>
      ) : (
        <ol className="list-decimal pl-8 mb-5">{props.children}</ol>
      )),
  }

  return (
    <div className="smallText md:medText lg:lgText">
      <PortableText blocks={blockData} serializers={serializer} />
    </div>
  )
}

export default BlockContentPrimary
