import React from "react"

const FacebookShare = ({ className, handle = "" }) => {
  const uri = `https://www.nofnec.ca${handle}`

  const encodedHandle = encodeURIComponent(`https://www.nofnec.ca${handle}`)

  return (
    <iframe
      src={`https://www.facebook.com/plugins/share_button.php?href=${encodedHandle}&layout=button_count&size=large&appId=1003338746797858&width=88&height=28`}
      width="88"
      height="28"
      // style={{ border: none, overflow: hidden }}
      className={`border-none overflow-hidden ${className}`}
      scrolling="no"
      frameBorder="0"
      allowFullScreen={true}
      allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
      title="Facebook Share"
    ></iframe>
  )
}

export default FacebookShare
