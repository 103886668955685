import React from "react"
import Img from "gatsby-image"
import { Link, graphql } from "gatsby"
import BlockContentPrimary from "../components/BlockContentPrimary"
import FacebookShare from "../components/FacebookShare"
import SEO from "../components/SEO"
import Layout from "../components/layout"
import { PageTitle } from "../components/SiteComponents"

const NewsPostTemplate = ({ data }) => {
  const newsPost = data.sanityPost
  const { _createdAt, publishedAt } = newsPost
  const date = publishedAt || _createdAt

  const rawMainContent = data.sanityPost._rawMainContent
  // was a "main image" provided in CMS?
  const mainImage = newsPost.mainImage ? newsPost.mainImage.asset.fluid : null

  return (
    <Layout>
      <div className="flex justify-center items-center newsPost">
        <SEO title={newsPost.title} description="NOFNEC News" />
        {/* floating inner box */}
        <div className="flex flex-col items-center  md:w-3/4 mt-20 mb-10 py-8 md:shadow-xl">
          {/* top matter */}
          <div className="text-center">
            <PageTitle>{newsPost.title}</PageTitle>
            <p className="italic">{date}</p>
            {newsPost.summary && (
              <div className=" bg-green-100 py-3 px-10 mb-10">
                <p className="italic text-green-600 "> {newsPost.summary}</p>
              </div>
            )}
          </div>
          {/* primary image. Only display if an image is provided */}
          {mainImage && (
            <div className="my-8 w-10/12">
              <Img
                fluid={mainImage}
                sizes={{ ...mainImage, aspectRatio: 2 / 1 }}
              />
            </div>
          )}
          {/* text content */}
          <article className="w-full px-5 md:px-20">
            {data.sanityPost._rawMainContent ? (
              <>
                <BlockContentPrimary blockData={rawMainContent} />
              </>
            ) : (
              <div>
                <h1>Error: No content provided</h1>
              </div>
            )}
            {/* Test Content */}
          </article>
          {/*formatfor button py-3 px-5 uppercase tracking-wider font-semibold bg-red-600 text-white mt-5 hover:bg-red-200 hover:text-red-700 */}
          <Link className="btn mt-5 mx-10 pb-2 text-center" to="/news">
            Return to News Posts
          </Link>
          {/* Facebook Share Button */}
          <FacebookShare
            className="mt-10"
            handle={`/news/${newsPost.slug.current}`}
          />
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query NewsPostQuery($title: String!) {
    sanityPost(title: { eq: $title }) {
      id
      title
      summary
      mainImage {
        asset {
          fluid {
            ...GatsbySanityImageFluid
          }
        }
      }
      _rawMainContent(resolveReferences: { maxDepth: 10 })
      slug {
        current
      }
      _createdAt(formatString: "MMMM D, YYYY")
      publishedAt(formatString: "MMMM D, YYYY")
    }
  }
`

export default NewsPostTemplate
