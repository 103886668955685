import React from "react"

export const PageTitle = ({ children, className }) => (
  <h1 className={`text-center tracking-wide ${className} text-6xl`}>
    {children}
  </h1>
)

export const SubTitle = ({ children, className }) => (
  <h2 className={`text-center text-5xl ${className}`}>{children}</h2>
)

export const PageContainer = ({ children, className }) => (
  <div className={`flex flex-col p-10 items-center  ${className}`}>
    {children}
  </div>
)

export const H2 = ({ className, children }) => (
  <h2 className={`${className}`}>{children}</h2>
)

export const CenteredContainer = ({ className, children }) => (
  <div className={`w-11/12 md:w-5/6 lg:w-4/6 ${className}`}>{children}</div>
)
